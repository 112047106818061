@import "../../../scss/variables/colors";
@import "../../../scss/modules/listPage";
@import "../../../scss/modules/page";
@import "../../../scss//modules/breadcrumbs";

.wrapper {
  padding: 2rem;
}
.content {
  background-color: white;
  padding: 2rem;
  max-width: 50rem;
  margin: 0 auto;
}
.nav {
  background-color: $color-blue;
  padding: 1rem 2rem;
  max-width: 50rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 0.25rem 0.25rem;
  a {
    color: white;
    text-decoration: none;
  }
}

.audio {
  display: block;
  width: 100%;
}

.backButton {
  height: 2em;
  width: 2em;

  &:hover {
    color: #999999;
  }
}
