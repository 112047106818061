@import "../../scss/variables/colors";


.container {
    background-color: $color-grey-light;
    border-radius: 0.7em;
    padding: 1em;
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainChild {
    
}

.leftContainer {
    display: flex;
    align-items: center;
}

.button {
    font-size: 1em;
    cursor: pointer;
    &:hover {
        color: $color-stone;
    }
}

.timerContainer {
    
}

.timer {
    font-size: 1em;
    margin-left: 0.7em;
    width: max-content;
}

.volumeControls {
    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 40%;
}

.volumeSlider {
    width: 80%;
    flex-shrink: 1;
}

.seekSliderWrapper {
    width: 100%;
}

.seekSlider {
    width: 100%;
}

