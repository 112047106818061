@import "../../scss/variables/";
@import "../../scss/mixins/index";
@import "../../scss/modules/forms";

.wrapper {
    padding: 2rem;
  }
  .content {
    background-color: white;
    padding: 2rem;
    max-width: 50rem;
    margin: 0 auto;
  }

  .form {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 0.25rem;
    @include box-shadow;
  }

  .navbar {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    margin-bottom: 2em;
  }
  
  .link-base {
    font-size: 1em;
    outline: none;
    padding: 0em 1em;
    text-decoration: none;
    text-align: center;
    color: white;
    cursor: pointer;
    margin: 0em 0.5em;
    background-color: $color-green;
    border-radius: 0.25rem;
    border: solid white 2px;
  
    &:visited {
      color: white;
    }
  }
  
  .link {
    @extend .link-base;
  }
  
  .active {
    @extend .link-base;
    border: solid $color-stone 2px;
  }
  