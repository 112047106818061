@import "../../../scss/variables/";

%likeButtonBase {
  color: $color-stone;
  border: none;
  border-radius: 2em;
  outline: none;
  background-color: white;
  margin: 0 0.5em;
  text-decoration: none;
  font-size: 0.75em;
  overflow: hidden;
  line-height: 1;
  display: flex;
  cursor: pointer;
  
  align-items: center;
  .inner {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
  }
}

.icon {
  margin-right: 0.5em;
}

.likeButtonOff {
  @extend %likeButtonBase;

  .icon {
    color: $color-pink;
  }
}

.likeButtonOn {
  @extend %likeButtonBase;
  background-color: $color-red;
  color: white;
  .icon {
    color: currentColor;
  }
}

.removeButton {
  margin: 0;
  padding: 0.5em 1em;
  cursor: pointer;
  color: white;
  background-color: rgba(black, 0.2);
  outline: none;
  border: none;
}
