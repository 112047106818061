.button {
}

.screenReadrText:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.icon {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}
