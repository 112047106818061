@import "../../scss/variables/";
@import "../../scss/mixins/";

.introIcon {
  height: 12rem;
}

.intro {
  padding: 2rem;
  background-color: $color-olive;
  color: white;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 40em) {
    min-height: calc(100vh - 7rem);
  }
  h1 {
    font-size: 2rem;
  }
  a {
    color: white;
  }

  .inner {
    text-align: center;
  }
}

// Basic sections
.section {
  padding: 2rem;
  .inner {
    background-color: white;
    padding: 2rem;
    border-radius: 0.25rem;
    @include box-shadow;
    text-align: center;
  }
}
// Big sections width bg images
.windowBlock {
  padding: 2rem;
  text-align: center;
  width: 100vw;
  min-height: 50vw;
  display: grid;
  grid-template-rows: 50vh min-content 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-attachment: fixed;

  @media screen and (max-width: 600px) {
    background-attachment: scroll;
  }

  background-color: $color-paleBlue;
  .inner {
    grid-row: 2;
    background-color: white;
    padding: 2rem;
    border-radius: 0.25rem;
    @include box-shadow;
  }
}

.grid {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1em;

  @media (min-width: 40rem) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.gridIcon {
  display: block;
  height: 6rem;
  width: 100%;
  margin-bottom: 0.5em;
}

.inner,
.innerForTabs {
  max-width: 60rem;
  margin: 0 auto;
}

// Trial and CTA stuff

.trial {
  padding: 2rem;
  padding: 2rem 2rem 1rem;
  background-color: $color-marble;
}

.ctaSection {
  background-color: $color-marble;
  padding: 1rem;
  text-align: center;

  @media (min-height: 45rem) {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

a.cta {
  background-color: $color-blue;
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 0.4em;
  font-size: larger;
  font-weight: 400;
  @include box-shadow;
}

.notReady {
  padding-top: 1rem;
  font-size: smaller;
  a {
    color: $color-blue;
  }
}

.footerWrapper {
  position: sticky;
  top: 100vh;
  background-color: $color-stone;
  padding: 2rem;
}
