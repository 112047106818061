@import ".././../scss/modules/landingPageBasics";

.section {

    margin-top: 1rem;

    .inner {
      background-color: white;
      padding: 2rem;
      border-radius: 0.25em;
      @include box-shadow;
    }
}

.images {
    display: flex;
    max-width: 100%;
    gap: 1rem;
}

.imgContainer {

}

.image {
    max-width: 100%;
}