@import "../../../scss/variables/";
@import "../../../scss/mixins";
@import "../../../scss/modules/buttons";

.languagesButton {
  font-size: inherit;
  background-color: $color-blue;
  color: white;
  border: none;
  padding: 0.5em;
  border-radius: 0 0 0.25em 0.25em;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

.languages {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1rem;

  .button,
  .buttonPrimary {
    width: 100%;
  }
}

.modal {
  background-color: white;
  width: 100%;
  max-width: 30em;
  padding: 1rem;
  margin: auto;
  transform: translateY(100vh);
  border-radius: 0.25em;
  @include box-shadow;
  @include modalSlideIn;
}

.modalOverlay {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(white, 0.5);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include modalShadeFade;
}
