@import "../../../scss/variables/";
@import "../../../scss/modules/card";
@import "../../../scss/modules/buttons";
%likeButtonBase {
  color: $color-stone;
  border: none;
  padding: 0.5em 1em;
  border-radius: 2em;
  outline: none;
  background-color: white;
  margin: 0em 0.5em;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.75em;
}

.icon {
  width: 2em;
  height: 2em;
  margin: 0 2em;
}
.inner {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  @media (min-width: 50rem) {
    padding-bottom: 2rem;
  }
}

.likeButtonOff {
  @extend %likeButtonBase;
  .icon {
    color: $color-pink;
  }
}

.likeButtonOn {
  @extend %likeButtonBase;
  .icon {
    color: $color-red;
  }
}
