@import "../../../scss/variables/";
@import "../../../scss/mixins/";

.toggle {
  font-size: inherit;
  background-color: $color-blue;
  color: white;
  border: none;
  border-radius: 0.25em 0.25em 0 0;
  padding: 0.5em;
  display: block;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

.navbar {
  display: none;
  gap: 1em;
}

.menuModalOverlay {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(white, 0.5);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include modalShadeFade;
}
.menuModal {
  color: white;
  background-color: $color-stone;
  padding: 1em;
  width: 30rem;
  max-width: 100%;
  font-size: larger;
  border-radius: 0.25em;
  @include box-shadow;
  @include modalSlideIn;
}

@media (min-width: 40em) {
  .toggle,
  .menuModalOverlay {
    display: none;
  }
  .navbar {
    display: flex;
  }
}

.link,
a.link {
  font-size: 1em;
  background-color: transparent;
  border: none;
  outline: none;
  display: block;
  padding: 0.5em 0;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

a.linkActive,
.linkActive {
  @extend .link;
  color: $color-yellow;
}
