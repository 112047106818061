@import "../variables/";

.headerWrapper {
  background-color: $color-olive;
  color: white;
  padding: 2rem;
}

.header {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
}
.headerTitle {
  font-size: 2rem;
}
.headerIcon {
  width: 6rem;
  height: 6rem;
}
