@import "../../../../scss/modules/buttons";

.demographics {

  h2 {
    margin-bottom: 0px;
  }
}

.inputsWrapper {
  margin-bottom: 1em;
}

.inputSplit {
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    width: 48%;
  }
}

@media (max-width: 600px) {
  .inputSplit {
    flex-direction: column;
    width: 100%;

    div {
      width: 100%;
    }
  }
}
