@import "../../../../scss/variables/colors";

.icons {
  height: 2em;
  a {
    text-decoration: none;
  }
}

.icon {
  text-decoration: none;
  color: white;
  font-size: 2.5em;
  margin-right: 0.25em;

  &:hover {
    color: $color-pink;
  }
}
