@mixin modalShadeFade {
  animation: shadeFade 0.25s ease-in-out forwards;
}

@mixin modalSlideIn {
  animation: slideIn 0.5s ease-in-out forwards;
}

@keyframes shadeFade {
  0% {
    background-color: rgba(white, 0);
  }
  100% {
    background-color: rgba(white, 0.5);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0.5;
    transform: translateY(100vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
