@import "./scss/variables/";
@import "./scss/modules/mediaQueries";

html {
  font-family: $font-body;
  background-color: $color-paleBlue;
  color: black;
  font-size: 1.2em;
  line-height: 1.7;
  box-sizing: border-box;

  @include responsive(tab-port) {
    font-size: 1em;
  }

  @include responsive(phone) {
    font-size: 0.8em;
  }

  @include responsive(small-phone) {
    font-size: 0.7em;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-family: $font-headings;
  font-weight: 400;
  line-height: 1.3;

  &:first-child {
    margin-top: 0;
  }
}

a {
  color: $color-blue;

  &:visited,
  &:active {
    color: $color-purple;
  }
}

q {
}

.App {
  min-height: 100vh;
  // display: grid;
  // grid-template-rows: min-content 1fr min-content;
}

.appHeader {
  // grid-row: 1;
}

.userContent {
  // grid-row: 2;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url(./assets/images/steppingstones.svg);
  backface-visibility: hidden;
}

.userNav {
  position: sticky;
  bottom: 0;
  z-index: 10;
  
  background-color: $color-mint;
}

$track-height: 4px;
$track-colour: $color-blue;
$thumb-size: 20px;

// sliders

input[type="range"] {
  background-color: initial;
  border: none;
  box-shadow: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;

  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: $thumb-size;
  width: $thumb-size;
  border-radius: 50%;
  background-color: $track-colour;
  cursor: pointer;

  margin-top: -1 * (($thumb-size/2)- ($track-height/2));
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: $color-blue;
}
input[type="range"]::-moz-range-track {
  background-color: white;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: $color-blue;
}
input[type="range"]::-ms-fill-upper {
  background-color: white;
}

input {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}
