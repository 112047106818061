@import "../../scss/modules/forms";

.field {
    margin-top: 0.7em;
    display: flex;
    flex-direction: column;
}

.label {
    width: 100% !important;
}

.dropdown {
    width: 100%;
}

.helpRed {
    color: #ae0f0f;
    border-color: black;
    font-weight: bold;
}

.helpGreen {
    color: #096209;
    border-color: black;
    font-weight: bold;
}