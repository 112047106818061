@import "../../scss/variables/";

.header {
  grid-row: 1;
  background-color: $color-stone;
  color: white;
  a {
    color: white;
  }
  display: grid;
  gap: 1rem;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
}
.logoWrapper {
  padding: 1rem;
  grid-row: 1/-1;
}
.languageWrapper,
.navWrapper {
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
}

.logo {
  display: block;
  fill: currentColor;
  height: 100%;
  width: auto;
}

@media (min-width: 40em) {
  .logoWrapper {
    padding: 1rem 2rem;
  }
  .languageWrapper,
  .navWrapper {
    padding: 0 2rem;
  }
}
