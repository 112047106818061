@import "../../scss/variables/";

.icon {
    display: block;
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
}



.A2HSPopup {
    grid-row: -2;
    background-color:$color-pink-light;
    color:$color-stone;
    padding:1rem;
    position: sticky;
    bottom: 0;
    z-index: 10;
}

.A2HSContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    a {
        color: white;
    }
}

.A2HSButtons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    
}

.button {
    background-color: $color-blue;
    color: white;
    text-decoration: none;
    padding: 0.4rem 1.2rem;
    display: block;
    width: 100%;
    text-align: center;
    border-radius: 0.4em;
    font-size: larger;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;
    //@include box-shadow;
}

.greenButton {
    background-color: $color-green;
}

.greyButton {
    background-color: $color-stone;
}


@media (min-width:40em){
    .A2HSPopup{
        padding:1rem 2rem;
    }
}