@import "../../../scss/variables/";

.wrapper {
    margin: 0px 0em 3em 0px;
}

.question {
    font-weight: 700;
    margin-bottom: 0.5em;
}

.slider {
    width: 100%;
    height: 300;
}

.sliderEnabled {
    color: #00759c !important; 
}
.sliderDisabled {
    color: grey !important;
}

.root {
    margin-top: 1rem;
    margin-left: 1rem;
    height: 200px
}

.answer {
    background-color: $color-marble;
    padding: 0.5rem 1rem 2.5rem;
}
