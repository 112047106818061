@import ".././../scss/modules/landingPageBasics";

.sections {
  display: grid;
  padding: 2rem;
  gap: 2rem;
}

.section {
  .inner {
    background-color: white;
    padding: 2rem;
    border-radius: 0.25em;
    @include box-shadow;
  }
}

.sectionTitle {
  text-align: left;
}

.links {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
}
