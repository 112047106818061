@import ".././../scss/modules/landingPageBasics";

.content {
    background-color: white;
    padding: 2rem;
    max-width: 50rem;
    margin: 20px auto;

    h2, h3, h4 {
        font-weight: bolder;
    }

}
  