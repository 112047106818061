@import "../../scss/variables/";

.fancyQuote {
  quotes: "“" "”";
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.6;
  position: relative;
  margin: 0;
  padding-left: 1.3em;
  max-width: 30em;
  margin: 0 auto;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1;
    content: open-quote;
    font-size: 3em;
    color: $color-pink;
  }
}
