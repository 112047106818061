@import "../../scss/modules/hero";
@import "../../scss/modules/listPage";
.stepHeader {
  display: flex;
  align-items: center;
}

.stepHeaderIcon {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.routineIcon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.noRoutinesMsg {
  font-size: x-large;
  text-align: center;
}
