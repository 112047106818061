@import "../../scss/modules/landingPageBasics";
@import "../../scss/modules/numberedBlocks";
@import "../../scss/modules/tabs";

.section .inner {
	text-align: left;
}
.sectionTenThings {
	@extend .section;
	background-color: $color-stone;
}
.sectionFaq {
	background-color: white;
}
.sectionResources {
	@extend .section;
	background-color: $color-yellow;
}
.sectionResourcesTitle {
	text-align: center;
}
.resources {
	margin: auto;
	padding: 0;
	display: grid;
	gap: 1rem;
	max-width: 75rem;
	@media (min-width: 50rem) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
