.logos {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  @media (min-width: 60rem) {
    justify-content: flex-end;
  }
}

.logo {
  display: block;
  width: auto;
  height: 3rem;
}
