@import "../../scss/modules/landingPageBasics";
@import "../../scss/modules/numberedBlocks";

@import "../../scss/modules/buttons";

.intro {
  min-height: unset;
}

.newRouteLink {
  @extend .buttonPrimary;
  width: 100%;
}

.sectionFaq {
  background-color: $color-marble;
}
