@import "../../scss/variables/";

.nav {
  color: $color-stone;
  display: flex;
  max-width: max-content;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

a.link {
  display: block;
  padding: 1em;
  color: currentColor;
  text-decoration: none;
}

a.active {
  background-color: rgba(white, 0.5);
}

.icon {
  display: block;
  width: 3rem;
  height: 3rem;
  margin: 0 auto;
}
