@import "../../../scss/modules/forms";
@import "../../../scss/variables/colors";

.field {
    margin-top: 0.7em;
    display: flex;
    flex-direction: column;
}

.label {
    width: 100% !important;
}

.dropdown {
    width: 100% !important;
    position: relative;
}

.helpRed {
    color: #ae0f0f;
    border-color: black;
    font-weight: bold;
}

.helpGreen {
    color: #096209;
    border-color: black;
    font-weight: bold;
}

.dropdown_main {
    align-items: center;
    overflow: hidden;
    width: 100% !important;
    display: flex;
    padding: 0.75em 0.75em 0.75em 0.75em;
    border: solid thin;
    outline: none;
    font-family: inherit;
    background-color: #E8E8E7;
    max-width: 100%;
    cursor: pointer;
    white-space: nowrap;
}

.dropdown_main_content {
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown_arrow {
    width: 1.4em;
    height: 1.4em;
}

.dropdown_list {
    width: 100% !important;
    background-color: white;
    border: solid thin;
    outline: none;
    position: absolute;
    z-index: 10;
    max-height: 400px;
    overflow-y: scroll;
    box-shadow: grey -2px 2px 15px;
}

.dropdown_option {
    width: 100% !important;
    padding: 0.5em 2em;
    border: solid thin #E8E8E7;
    cursor: pointer;
    
    &:hover {
        background-color: $color-paleBlue;
    }
}

.placeholder {
    color: grey;
    cursor: auto;

    &:hover {
        background-color: white;
    }
}

.noHover {
    &:hover {
        background-color: inherit;
    }
}   

.selected {
    background-color: $color-paleBlue;
    &:hover {
        background-color: $color-paleBlue;
    }
}