// @import ".././../scss/variables/colors";
@import ".././../scss/modules/landingPageBasics";

.info {
  padding: 2rem;
  background-color: $color-mint;

  .inner {
    background-color: white;
    padding: 2em;
    border-radius: 0.25rem;
    @include box-shadow;
  }
}

.intro {
  padding: 2rem;
  background-color: $color-olive;
  color: white;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 40em) {
    min-height: calc(100vh - 7rem);
  }
}

.helpTo {
  @extend .windowBlock;
  background-image: url("../../assets/images/fjord.svg");

  li {
    border-top: solid $color-paleBlue;
    padding-top: 1em;
  }
}

