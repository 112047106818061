@import "../variables/colors";

.numberedBlocks {
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2em;
  display: grid;
  text-align: left;

  @media (min-width: 40em) {
    grid-template-columns: 1fr 1fr;
  }

  li {
    position: relative;
    background-color: $color-marble;
    border-top: solid thick;
    padding: 1.5rem 1rem 1rem;
    counter-increment: li-count;

    &:before {
      line-height: 1;
      position: absolute;
      top: -1rem;
      left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: $color-stone;
      color: white;
      text-align: center;
      font-weight: bold;
      font-size: x-large;
      content: counter(li-count);
    }
  }
}
