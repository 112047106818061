@import "../../../scss/modules/card";
@import "../../../scss/modules/forms";
@import "../../../scss/modules/buttons";
@import "../../../scss//modules/modal";

.card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: solid 2em;
}
.relationships {
  border-left-color: $color-pink;
}
.work {
  border-left-color: $color-blue;
}
.growth {
  border-left-color: $color-lilac;
}
.health {
  border-left-color: $color-mint;
}
.other {
  border-left-color: $color-yellow;
}

.valueStatement {
  margin-top: 0;
}

.actionDelete {
  @extend .actionButton;
  background-color: $color-red;
  margin-left: 0.5em;
}

.isDragging {
  transform: scale(1.1);
  box-shadow: 0.2em 0.4em 0.8em 0.2em rgba(0, 0, 0, 0.2);
}
