.links {
  list-style: none;
  margin: 0 0 2rem;
  padding: 0;

  li {
    display: inline-block;
  }

  a {
    color: white;
    text-decoration: none;
    padding: 0.5em 0 0 0;
    margin-right: 0.75em;
  }
}

a.current {
  border-bottom: solid white;
}
