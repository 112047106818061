@import "../../scss/variables/";
@import "../../scss/modules/listPage";
@import "../../scss/modules/buttons";

.stone {
  margin-left: 2rem;
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
}

.redo,
.locked {
  font-size: small;
}
.routineIcons {
  display: flex;
}
.routineIcon {
  display: block;
  width: 2em;
  height: 2em;
  background-color: white;
  border-radius: 0.2em;
  padding: 0.25em;
  margin-left: 0.5em;
}
