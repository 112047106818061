@import "../../scss/variables/colors";
@import "../../scss/mixins/";

.wrapper {
	background-color: white;
	padding: 1rem;
	display: grid;

	grid-template-columns: 1fr 2fr;
	grid-template-rows: min-content min-content;
	gap: 1em;

	.links {
		grid-column: 2;
	}

	.image {
		grid-row: 1/-1;
	}

	@media (min-width: 50rem) {
		gap: 0.5rem;
		grid-template-columns: unset;
		grid-template-rows: max-content 1fr min-content;
		.image {
			grid-row: 1;
		}

		.links {
			grid-column: 1;
		}
	}
}

.image {
	width: 100%;
	height: auto;
	@include box-shadow;
}
.title {
	font-size: xx-large;
	margin: 0;
}

.links {
	list-style: none;
	padding: 0.2rem;
	margin: 0;
	display: grid;
	gap: 0.2rem;
	background-color: $color-paleBlue;
}
.link {
	line-height: 1.2;
	display: block;
	padding: 0.5rem;
	background-color: rgba(white, 0.5);
	&:hover {
		background-color: white;
	}
}

.wrapperVideo {
	background-color: white;
	padding: 1rem;
	display: grid;
	gap: 0.5rem;
	grid-column: 1/-1;

	@media (min-width: 50rem) {
		column-gap: 1rem;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr min-content;

		.video {
			grid-row: 1/-1;
		}
	}
}
