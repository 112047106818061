@import "../../scss/variables/colors";

.previousAnswer {
  background-color: $color-marble;
  margin-bottom: 1em;
  border-left: solid thick;
  padding: 1em;
  font-size: 110%;

  .title {
    font-size: small;
  }

  .content {
    &::before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
    * {
      margin-top: 0px;
    }
  }
}

.noPreviousAnswer {
  background-color: $color-paleBlue;
  margin-bottom: 1em;
  border-left: solid thick;
  padding: 1em;
  font-size: 110%;
}
