@import "../../scss/variables/";
.nav {
  color: white;
  background-color: $color-stone;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

a.link {
  display: block;
  color: white;
  padding: 0.5em;
  flex: 1;
  text-align: center;
  text-decoration: none;
}

a.active {
  background-color: $color-olive;
}
