@import "../variables/";
@import "../mixins/";
@import "../modules/card";

.icon {
  width: 12rem;
  height: 12rem;
}

.wrapper {
  padding: 2rem;
}

.list {
  list-style: none;
  max-width: 50rem;
  padding: 0;
  margin: 0 auto 2rem;
}

.item {
  margin-top: 1rem;
  border-radius: 0.1rem;
}
