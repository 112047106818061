@import "../variables/colors";

.breadcrumbs {
  text-align: center;
  background-color: $color-mint;

  a {
    display: inline-block;
    padding: 0.5em 0;
    color: currentColor;
  }
}
