@import ".././../scss/modules/landingPageBasics";

.notReadyConsider {
  @extend .windowBlock;
  background-image: url("../../assets/images/tunnel.svg");

  .grid {
    grid-template-columns: 1fr;
    @media (min-width: 60em) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  li {
    border-top: solid $color-orange;
    padding-top: 1em;
  }
}

.notReadyReminder {
  @extend .windowBlock;
  background-image: url("../../assets/images/fork.svg");
}
