.resultsTable {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
}

.cell {
    $font-size: calc(max(min(1vw,16px),12px));
    font-size: $font-size;
    border-bottom: 1px solid black;
    padding: 5px;
    max-width: 100%;
}

.header {
    cursor: pointer;
    &:hover {
        color: grey;
    }
}