@import "../../scss/variables/";

.ProgressBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px 2em;
  flex-grow: 0;
}

.bar-base {
  height: 3px;
  width: 10%;
  margin: 0 0.1em;
  background-color: $color-stone;
  flex-shrink: 1;
  padding: 0;
}

.barComplete {
  background-color: $color-marble;
  @extend .bar-base;
}

.barActive {
  background-color: white;
  @extend .bar-base;
}

.barIncomplete {
  @extend .bar-base;
}
