// core palette
$color-stone: #404a45;
$color-marble: #faf1ee;
$color-olive: #80918b;
$color-pink: #e6bbaa;
$color-pink-light: #fae3da;
$color-mint: #bfdcc1;
$color-paleBlue: #ddf5f5;
$color-lilac: #aca4c1;

// strong palette
$color-red: #db6b5e;
$color-orange: #df8951;
$color-yellow: #fabb26;
$color-green: #468f79;
$color-blue: #00759c;
$color-purple: #5a4f8b;

// neutrals
$color-grey-light: #eaeaea;
