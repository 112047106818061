@import "../../../scss/modules/forms";

.wrapper {
    margin: 2em 0;

    textarea {
        width: 100%;
    }
}

.answers {
    margin-left: 0.5em;
}

.answer {
    display: grid;
    grid-template-columns: 1fr 14fr;
    align-items: center;
    
}

.checkboxWrapper {
    width: 3em;
    height: 3em;
    margin-right: 1em;
    cursor: pointer;
}

.checkbox {
    width: 50%;
    height: 50%;
    margin: 25%;
    
}
