@import "../variables/colors";
@import "../mixins/index";

.message {
  margin-top: 2rem;
  font-size: 0.8em;
  padding: 0.7em;
  border-radius: 0.25em;
  border: thin solid;
  @include box-shadow;
}

.isError {
  background-color: $color-red;
  color: white;
  border-color: $color-red;
  &:before {
    display: inline-flex;
    background-color: white;
    color: $color-red;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
    content: "!";
  }
}

.isSuccess {
  background-color: $color-mint;
  border-color: $color-green;
}
