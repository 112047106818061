@import "../variables/";

.headerWrapper {
  background-color: $color-olive;
  color: white;
  padding: 2rem;
}

.header {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 2rem;
}
.icon {
  width: 12rem;
  height: 12rem;
}

.wrapper {
  padding: 2rem;
}

.content {
  max-width: 50rem;
  padding: 0;
  margin: 0 auto;
  background-color: white;
  border-radius: 0.25rem;
}
