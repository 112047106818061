@import "../../../scss/variables/colors";
@import "../../../scss/mixins";
@import "../Navbar/Navbar.module.scss";

.items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.topItem {
  display: flex;
  position: relative;
  z-index: 100;
}

.toggleSubItems {
  font-size: 1em;
  background-color: transparent;
  border: none;
  outline: none;
  display: block;
  padding: 0.5em 0;
  margin-left: 0.75em;
  text-decoration: none;
  color: white;
  cursor: pointer;

  @media (max-width: 400px) {
    font-size: 0.8em;
    padding: 0.3em 0;
  }

  @media (max-width: 320px) {
    font-size: 0.7em;
    padding: 0.1em 0;
  }
}
.subItems {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: $color-marble;
  @include box-shadow;
  position: absolute;
  top: calc(100% - 0.2em);
  right: 0;
  text-align: right;
}

.subItem .link {
  color: $color-stone;
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 0.5em 1em;

  &:hover {
    background-color: white;
  }
}
