@import "../../scss/variables/index";
@import "../../scss//modules/breadcrumbs";

.headerWrapper {
  background-color: $color-olive;
  color: white;
  padding: 2rem;
}

.header {
  max-width: 50rem;
  margin: 0 auto;
  display: flex;
}

.title {
  margin: 0;
}

.icon {
  flex-shrink: 0;
  display: block;
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
}
