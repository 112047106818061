@import "../../scss/variables/colors";
@import "../../scss/mixins/index";

.wrapper {
  display: grid;
  gap: 2rem;
  padding: 2rem;
}

.section {
  width: 100%;
  margin: 0 auto;
  max-width: 60rem;
}
.description {
  margin: 1em 0;
}
.questions {
  display: grid;
  gap: 0.5rem;
}

.questionWrapper {
  overflow: hidden;
  border-radius: 0.25em;
  @include box-shadow;
}
.question {
  background-color: $color-mint;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: larger;
}

.answer {
  padding: 1rem;
  background-color: white;

  blockquote {
    background-color: $color-marble;
    margin-bottom: 1em;
    border-left: solid thick;
    padding: 1em;
    font-size: 110%;
    margin: 0;

    &::before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
    * {
      margin-top: 0px;
    }
  }
}
