@import "../../scss/variables/";
@import "../../scss/mixins/";

.scoreDefinitions {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1rem;
}

.scoreDefinitionLow {
  padding: 1rem;
  border-radius: 0.5em;
  background-color: $color-lilac;
}

.scoreDefinitionMed {
  padding: 1rem;
  border-radius: 0.5em;
  background-color: $color-paleBlue;
}

.scoreDefinitionHigh {
  padding: 1rem;
  border-radius: 0.5em;
  background-color: $color-mint;
}

.current {
  border: solid $color-stone;
  @include box-shadow;
}
