@import "../../../scss/modules/listPage";
@import "../../../scss/variables/colors";


.wrapper {
    padding: 2rem;
}
.content {
    background-color: white;
    padding: 2rem;
    max-width: 50rem;
    margin: 0 auto;
}