@import "../../scss/variables/colors";
@import "../../scss/variables/fonts";
@import "../../scss/mixins/index";
@import "../../scss/modules/landingPageBasics";

// **********************
// WHO FOR?
// **********************

.whoFor {
  padding: 2rem;
}

.whoForContent {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
}

// **********************
// RESEARCH
// **********************

.research {
  padding: 2rem;
  background-color: $color-mint;

  .inner {
    background-color: white;
    padding: 2em;
    border-radius: 0.25rem;
    @include box-shadow;
  }
}

// **********************
// TEAM
// **********************

.team {
  padding: 2rem;
  background-color: $color-marble;

  .inner {
    max-width: 100%;
  }
}

.teamMembers {
  list-style: none;
  padding: 0;
  margin: 1em 0;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(34em, 1fr));

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

.member {
  background-color: white;
  padding: 2rem;
  border-radius: 0.75em;
  @include box-shadow;
  overflow: hidden;
  text-align: left;
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.memberImageWrapper {
  flex-grow: 0;
  padding-right: 2rem;
  padding-bottom: 1rem;
}

.memberImage {
  display: block;
  max-width: 8rem;
  height: auto;
  border-radius: 0.25rem;
}
.memberContent {
  flex-grow: 1;
}

.memberName {
  margin: 0;
  font-size: 1.5em;
}
.memberRole {
  font-family: $font-headings;
  font-style: italic;
}

.memberBlurb {
  padding-top: 1rem;
  border-top: solid thin;
  margin-top: 1rem;
}

// **********************
// ACKNOWLEDGEMENTS
// **********************
.acknowlegements {
  padding: 2rem;
  color: white;
  background-color: $color-olive;

  h3 {
    padding-top: 1rem;
    border-top: solid thin;
    margin-top: 1rem;
  }
}

// **********************
// SUPPORTERS
// **********************
.supportersWrapper {
  background-color: white;
  padding: 1rem;
  text-align: center;
}

.supportersTitle {
  color: $color-olive;
}

.supporters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.supporterLogo {
  height: 3.5em;
  width: auto;
  max-width: 10em;
  object-fit: contain;
}
