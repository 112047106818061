@import "../../../scss/variables/";
@import "../../../scss/mixins/index";

@import "../../../scss/modules/forms";
@import "../../../scss/modules/errors";

.wrapper {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 2rem;
}

.form {
  background-color: white;
  @include box-shadow;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.25rem 0.25rem 0 0;
}

.formActions {
  display: flex;
  justify-content: space-between;
}

.buttonSubmit {
  margin-top: 1rem;
  align-self: flex-start;
}

.wrongViewMessage {
  background-color: $color-marble;
  padding: 1rem 2rem;
  border-radius: 0 0 0.25rem 0.25rem;
}

.forgotYourPassword {
  margin-top: 1rem;
  text-align: right;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
