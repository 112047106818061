// @import ".././../scss/variables/colors";
@import ".././../scss/modules/landingPageBasics";

.intro {
  padding: 2rem;
  background-color: $color-olive;
  color: white;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 40em) {
    min-height: calc(100vh - 7rem);
  }
}

.helpTo {
  @extend .windowBlock;
  background-image: url("../../assets/images/fjord.svg");

  li {
    border-top: solid $color-paleBlue;
    padding-top: 1em;
  }
}

.features {
  @extend .windowBlock;
  background-image: url("../../assets/images/forest.svg");
  li {
    border-top: solid $color-lilac;
    padding-top: 1em;
  }
}

.testimonialsWrapper {
  background-color: $color-mint;
  padding: 4rem 1rem;
  text-align: center;
}

.testimonials {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1em;
  @media (min-width: 32rem) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.testimonial {
  text-align: left;
  blockquote {
    position: relative;
    margin: 0;
    padding: 1em 2rem;
    background-color: white;
    border-radius: 1em;
    &:after {
      display: block;
      content: "";
      width: 0;
      height: 0;
      border: solid 0.75rem transparent;
      border-top-color: white;
      border-left-color: white;
      position: absolute;
      top: 100%;
      left: 2rem;
    }
  }
  .attribution {
    text-align: right;
    padding: 0 2em 0 4.5em;
  }
}

.about {
  background-color: $color-olive;
  color: white;
  padding: 2rem 1rem;
  text-align: center;
}

.aboutIntro {
  padding: 1em 0;
  border-top: solid;
  border-bottom: solid;
}

.aboutPoints {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;
  display: grid;
  gap: 1em;
  @media (min-width: 32rem) {
    grid-template-columns: 1fr 1fr;
  }
}

a.aboutLink {
  display: inline-block;
  padding: 0.5em 1em;
  color: $color-stone;
  background-color: $color-marble;
  text-decoration: none;
  border-radius: 0.25em;
  @include box-shadow;
}
