.footer {
  position: sticky;
  top: 100vh;
  display: grid;
  gap: 2rem;
  justify-content: center;
  align-items: top;
  @media (min-width: 60rem) {
    grid-template-columns: 1fr 1fr;
  }
}
