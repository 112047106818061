@import "../../scss/modules/mediaQueries";

.wrapper {
    text-align: center;
}

.question {
    font-weight: 700;
}

.emojis {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));;
    width: 100%;

    @include responsive(small-phone) {
        grid-template-columns: repeat(3, minmax(0, 1fr));;
        grid-template-rows: repeat(2, minmax(0, 1fr));;
    }
}

.emojiImg {
    display: block;
    width: 100%;
}

.emojiLabel {
    font-size: 1rem;
}

// the button
.emoji {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0.5em;
    img {
        -webkit-filter: grayscale(90%);
        filter: grayscale(90%);
    }
}

.selected {
    img {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
    }
}
