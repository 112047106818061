@import "../variables/";

%buttonBase {
  font-size: 1em;
  line-height: 1;
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  text-decoration: none;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
}

.button,
button.button,
[type="submit"].button,
a.button {
  @extend %buttonBase;
  background-color: $color-paleBlue;
  color: $color-stone;
}

.buttonPrimary,
button.buttonPrimary,
[type="submit"].buttonPrimary,
a.buttonPrimary {
  @extend %buttonBase;
  background-color: $color-blue;
  color: white;
}

.buttonCancel,
button.buttonCancel {
  @extend %buttonBase;
  background-color: $color-grey-light;
}

.buttonSubmit,
button.buttonSubmit,
[type="submit"].buttonSubmit,
a.buttonSubmit {
  @extend %buttonBase;
  background-color: $color-green;
  color: white;
}
