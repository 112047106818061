@mixin responsive($breakpoint) {
    @if $breakpoint == small-phone {
        @media only screen and (max-width: 350px) { @content };  
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: 600px) { @content };  
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 900px) { @content };  
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 1400px) { @content };  
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 1800px) { @content };  
    }
    
}