@import "../../scss/variables/colors";

.wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.2rem 0.1rem rgba($color-stone, 0.2);
}
