@import "../variables/";
@import "../mixins/";

.card {
  background-color: white;
  border-radius: 0.25rem;
  padding: 1rem 0 0;
  @include box-shadow;
  @media (min-width: 40em) {
    padding: 2rem 0 0;
  }
}

.inner {
  @media (min-width: 40em) {
    display: flex;
  }
}

.content {
  padding: 1rem 2rem;
  flex-grow: 1;
  @media (min-width: 40em) {
    padding: 0 2rem;
  }
}
.title {
  margin: 0;
}

.actions {
  background-color: $color-marble;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionButton {
  background-color: $color-blue;
  color: white;
  display: inline-block;
  border: none;
  padding: 0.5em 1em;
  border-radius: 0.2em;
}
