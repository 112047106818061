@import "../variables/index";
@import "buttons";

label {
  font-weight: 600;
}

[type="text"],
[type="email"],
[type="password"],
[type="number"],
textarea {
  font-size: 1em;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0.75em;
  border-radius: 0.2em 0.2em 0 0;
  border: solid thin;
  outline: none;
  font-family: inherit;

  &:last-child {
    border-radius: 0.2em;
  }
}

select {
  font-size: 1em;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0.75em 2em 0.75em 0.75em;
  border-radius: 0.2em 0.2em 0 0;
  border: solid thin;
  outline: none;
  font-family: inherit;

  &:last-child {
    border-radius: 0.2em;
  }
}

.fieldWrapper {
  label {
    display: block;
  }
}

.fieldWrapper + .fieldWrapper {
  margin-top: 1rem;
}

.help {
  font-size: smaller;
  background-color: $color-marble;
  padding: 0.5em;
  border-radius: 0 0 0.2em 0.2em;
  border: solid thin;
  border-top: none;
  width: 100% !important;
}
