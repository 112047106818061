@import "../variables/colors";

.tabWrapper {
  margin: 0 auto;
}

.tabBar {
  display: flex;
  gap: 0.5rem;
  justify-items: stretch;
}

.tab {
  border: none;
  padding: 0.5em 1em;
  font-size: inherit;
  color: white;
  background-color: $color-olive;
  border-radius: 0.2em 0.2em 0 0;
}

.tabActive {
  @extend .tab;
  background-color: $color-stone;
}

.tabContent {
  padding: 2rem;
  background-color: white;
  border-top: solid thick $color-stone;
  border-radius: 0 0 0.2em 0.2em;
}
