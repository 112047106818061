@import "../../scss/variables/";
@import "../../scss/modules/buttons";

.wrapper {
    padding-bottom: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    
    align-items: end;
}
