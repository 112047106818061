@import "../variables/colors";
@import "../mixins/index";

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.modal {
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 50em;
  @include box-shadow;
}

.modalClose {
  position: absolute;
  top: 0;
  right: 0;
}

.modalHeader {
  background-color: $color-olive;
  padding: 1rem 1rem 1rem 2rem;
  border-radius: 0.5rem 0.5rem 0 0;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .modalClose {
    position: relative;
    color: white;
    background-color: transparent;
    border: none;
    margin-left: 1rem;
    flex-grow: 0;
    justify-self: end;
  }
}

.modalContent {
  padding: 2rem;
  overflow-y: scroll;
  max-height: 70vh;

  .modalHeader + & {
    padding: 1rem 2rem 2rem;
  }
}

.allowOverflow {
  overflow-y: unset;
}
